<template>
  <div class="strat-plan">
    <IntroBlock />
    <div>
      <section
        v-for="(points, index) in planPoints"
        :key="points.id"
        :id="`section${index}`"
        :class="{ 'text-at-end': points.endText === false }"
      >
        <div class="half text">
          <h3>
            <span> {{ points.id }} </span>
            {{ points.title }}
          </h3>
          <ul>
            <li v-for="list in points.list" :key="list.id">
              <span v-html="list.item" />
            </li>
          </ul>
        </div>
        <div
          class="half image"
          :style="{ backgroundImage: 'url(' + points.img + ')' }"
        >
          <!-- <img
            src="https://tcnj.edu/custom/homepage/img/nursing-pathway.jpg"
            alt="test"
          /> -->
        </div>
      </section>
    </div>
    <div id="dashboard">
      <h3>Dashboard</h3>
      <ProgressBar
        title="Undergraduate enrollment"
        percent="65"
        figure="7,500"
      />
      <ProgressBar title="Graduate enrollment" percent="50" figure="3,500" />

      <ProgressBar title="Financial Aid" percent="75" figure="$100" />
      <ProgressBar title="Residential occupancy" percent="55" figure="6,000" />
      <ProgressBar title="Expense Management" percent="90" figure="7,600" />
    </div>
  </div>
</template>

<script>
import IntroBlock from "./components/IntroBlock.vue";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// import { CSSPlugin } from "gsap/CSSPlugin";
import ProgressBar from "./components/ProgressBar.vue";

export default {
  name: "App",
  components: {
    IntroBlock,
    ProgressBar,
  },
  data() {
    return {
      planPoints: [
        {
          id: "01",
          endText: false,
          title: "Benefit current and future generations of undergraduates",
          img: "https://strategicplanning.tcnj.edu/custom/strategic-plan/img/photo-1-min.jpg",
          list: [
            {
              item: "Transform the general education curriculum",
              id: 1,
            },
            {
              item: "Integrate career-readiness programs into the curriculum",
              id: 2,
            },
            {
              item: "Provide more ways for students to develop leadership skills",
              id: 3,
            },
            { item: "Improve housing quality and options", id: 4 },
            { item: "Expand the TCNJ experience for transfer students", id: 5 },
          ],
        },
        {
          id: "02",
          endText: true,
          title: "Serve new audiences of learners",
          img: "https://strategicplanning.tcnj.edu/custom/strategic-plan/img/photo-2-min.jpg",
          list: [
            {
              item: "Develop graduate and continuing education programs",
              id: 1,
            },
            { item: "Offer more dual and 4+1 degrees", id: 2 },
            { item: "Provide new modes of instruction", id: 3 },
            { item: "Grow early college programs", id: 4 },
            {
              item: "Enhance  academic  options in winter and summer sessions",
              id: 5,
            },
          ],
        },
        {
          id: "03",
          endText: false,
          title: "Bolster our impact at local, national, and global levels",
          img: "https://strategicplanning.tcnj.edu/custom/strategic-plan/img/photo-3-min.jpg",
          list: [
            {
              item: "Leverage institutional strengths to address community needs",
              id: 1,
            },
            { item: "Build external partnerships", id: 2 },
            { item: "Expand support for grants and sponsored research", id: 3 },
            { item: "Maximize community-engaged learning", id: 4 },
          ],
        },
        {
          id: "04",
          endText: true,
          title: "Realize our vision and promise ",
          img: "https://strategicplanning.tcnj.edu/custom/strategic-plan/img/photo-4-min.jpg",
          list: [
            {
              item: "Implement <em>We are TCNJ: A Strategy for Inclusive Excellence</em> to increase access and success for students, staff, and faculty from underrepresented and culturally diverse backgrounds; sustain an inclusive and equitable campus environment; and enhance systems, policies, practices, governance, and data to achieve inclusive excellence goals",
              id: 1,
            },
            {
              item: "Bolster marketing to attract applications, increase visibility, and highlight brand",
              id: 2,
            },
            {
              item: "Build cultural and physical capacity to achieve strategy goals",
              id: 3,
            },
          ],
        },
      ],
    };
  },
  mounted: function () {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(ScrollSmoother);
    gsap.registerPlugin(ScrollToPlugin);
    // gsap.registerPlugin(CSSPlugin);
    // this.fadeInListText();
    // this.timelineSetter();
    // this.listFadeOne();
    // this.listFadeTwo();
    // this.listFadeThree();
    // this.listFadeFour();
    // this.listFadeFive();
    // this.listFadeSix();
    this.firstTimeline();
    this.secondTimeline();
    this.thirdTimeline();
    this.lastTimeline();
    this.chartFadeIn();
    this.scrollToFirst();
    this.bouncingHoverArrow();
    // this.scrollToSection();
    // this.pathwaysFadeIn();
    // this.parallaxBground();
  },
  methods: {
    scrollToFirst() {
      document.querySelectorAll(".scroll-to-link").forEach((btn) => {
        btn.addEventListener("click", () => {
          gsap.to(window, {
            duration: 2,
            ease: "power4.out",
            scrollTo: { y: "#section0", offsetY: -115 },
          });
        });
      });
    },

    bouncingHoverArrow() {
      // https://codepen.io/snorkltv/pen/mdBamZG
      const item = document.querySelector("#down-arrow");
      gsap.defaults({ duration: 0.3 });
      const tl = gsap
        .timeline({ paused: true })
        .to("#down-arrow", { scale: 1.25 }, 0);

      item.addEventListener("mouseenter", () => tl.play());
      item.addEventListener("mouseleave", () => tl.reverse());

      // https://codepen.io/PointC/pen/GRZzBQM/822a4b48b9ddc37fd1ec3fc5172a5c65
      // let boxHover = document.querySelector("#box-hover");

      // boxHover.addEventListener("mouseover", () => {
      //   gsap.to("#down-arrow", {
      //     duration: 0.6,
      //     y: 70,
      //     ease: "circ.in",
      //     repeat: -1,
      //     yoyo: true,
      //   });
      // });
      // let tl = gsap.timeline({ paused: true });
      // tl.to("#down-arrow", {
      //   duration: 0.6,
      //   y: 70,
      //   ease: "circ.in",
      //   repeat: 3,
      //   yoyo: true,
      // });
      // boxHover.on("mouseover", function () {
      //   tl.play();
      // });
    },
    timelineSetter() {
      gsap.set("section li", { y: 30, opacity: 0 });
    },
    listFadeOne() {
      const listOne = gsap.from(
        "#section0 li",
        { y: 30, opacity: 0, duration: 0.3, stagger: 0.15, paused: true },
        2
      );

      ScrollTrigger.create({
        trigger: "#section0 li",
        start: "top 60%",
        onEnter: () => listOne.play(),
      });

      ScrollTrigger.create({
        trigger: "#section0 li",
        start: "top bottom",
        onLeaveBack: () => listOne.pause(0),
      });
    },
    listFadeTwo() {
      const listTwo = gsap.from(
        "#section1 li",
        { y: 30, opacity: 0, duration: 0.3, stagger: 0.15, paused: true },
        2
      );

      ScrollTrigger.create({
        trigger: "#section1 li",
        start: "top 60%",
        onEnter: () => listTwo.play(),
      });

      ScrollTrigger.create({
        trigger: "#section1 li",
        start: "top bottom",
        onLeaveBack: () => listTwo.pause(0),
      });
    },
    listFadeThree() {
      const listThree = gsap.from(
        "#section2 li",
        { y: 30, opacity: 0, duration: 0.3, stagger: 0.15, paused: true },
        2
      );

      ScrollTrigger.create({
        trigger: "#section2 li",
        start: "top 60%",
        onEnter: () => listThree.play(),
      });

      ScrollTrigger.create({
        trigger: "#section2 li",
        start: "top bottom",
        onLeaveBack: () => listThree.pause(0),
      });
    },
    listFadeFour() {
      const listFour = gsap.from(
        "#section3 li",
        { y: 30, opacity: 0, duration: 0.3, stagger: 0.15, paused: true },
        2
      );

      ScrollTrigger.create({
        trigger: "#section3 li",
        start: "top 60%",
        onEnter: () => listFour.play(),
      });

      ScrollTrigger.create({
        trigger: "#section3 li",
        start: "top bottom",
        onLeaveBack: () => listFour.pause(0),
      });
    },
    listFadeFive() {
      // gsap.set("#section3 li", { y: 30, opacity: 0 });
      const listFour = gsap.to(
        "#section3 li",
        // { y: 30, opacity: 0, duration: 0.3, stagger: 0.15 },
        { y: 0, opacity: 1, duration: 0.3, stagger: 0.15 }
      );
      ScrollTrigger.create({
        trigger: "#section3 li",
        start: "top 60%",
        onEnter: () => listFour.play(),
      });

      ScrollTrigger.create({
        trigger: "#section3 li",
        start: "top bottom",
        onLeaveBack: () => listFour.pause(0),
      });
    },
    listFadeSix() {
      const blocks = document.querySelectorAll("selection");

      blocks.forEach((block) => {
        const blockTimeline = gsap.timeline({
          scrollTrigger: {
            trigger: block,
            start: "top center",
            end: "bottom center",
            toggleActions: "play reverse play reverse",
            // markers: true,
          },
        });
        // Animate the header items
        // .from(block.querySelectorAll("section li"), {
        //   duration: 1,
        //   y: 40,
        //   opacity: 0,
        //   stagger: .25,
        // })

        // Animate the content items
        const items = block.querySelectorAll("section li");
        blockTimeline.from(
          items,
          {
            duration: 1,
            x: 40,
            opacity: 0,
            stagger: 0.25,
          },
          0
        );

        // Animate the ps in the items
        // items.forEach((item, i) => {
        //   blockTimeline.from(item.querySelectorAll("p"), {
        //     duration: 1,
        //     y: 100,
        //     opacity: 0,
        //     stagger: .25
        //   }, i * .25);
        // });
      });
    },
    firstTimeline() {
      gsap.set("#section0 li", { opacity: 0, y: 30 });
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#section0 .text",
          start: "top 50%",
          end: "bottom top",
          // markers: true,
          toggleActions: "play none none none",
        },
      });
      tl.to("#section0 li", {
        autoAlpha: 1,
        stagger: 0.25,

        // duration: 0.3,
        y: 0,
      });
    },
    secondTimeline() {
      gsap.set("#section1 li", { opacity: 0, y: 30 });
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#section1 .text",
          start: "top 50%",
          end: "bottom top",
          // markers: true,
          toggleActions: "play none none none",
        },
      });
      tl.to("#section1 li", {
        autoAlpha: 1,
        stagger: 0.25,

        // duration: 0.3,
        y: 0,
      });
    },
    thirdTimeline() {
      gsap.set("#section2 li", { opacity: 0, y: 30 });
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#section2 .text",
          start: "top 50%",
          end: "bottom top",
          // markers: true,
          toggleActions: "play none none none",
        },
      });
      tl.to("#section2 li", {
        autoAlpha: 1,
        stagger: 0.25,

        // duration: 0.3,
        y: 0,
      });
    },
    lastTimeline() {
      gsap.set("#section3 li", { opacity: 0, y: 30 });
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: "#section3 .text",
          start: "top 50%",
          end: "bottom top",
          // markers: true,
          toggleActions: "play none none none",
        },
      });
      tl.to("#section3 li", {
        autoAlpha: 1,
        stagger: 0.25,

        // duration: 0.3,
        y: 0,
      });
    },
    chartFadeIn() {
      let banners = gsap.utils.toArray(".bar-container");

      // let banners = document.querySelectorAll(".bar-container");
      let trig = document.querySelector("#dashboard");
      banners.forEach((chart) => {
        let chartFill = chart.querySelectorAll(".w3-container");
        // let chartHeadings = chart.querySelectorAll("p");
        let tl = gsap.timeline({ defaults: { stagger: 0.75 } }).from(
          chartFill,
          {
            opacity: 0.75,
            // ease: "power4.in",
            duration: 1,
            width: 0,
            stagger: 0.25,
          },
          0
        );
        // .from(chartHeadings, { y: 50, stagger: 0.25, autoAlpha: 0 }, 0);

        ScrollTrigger.create({
          trigger: trig,
          start: "top 50%",
          markers: false,
          toggleActions: "play none none reverse",
          animation: tl,
        });
      });
    },
    // chartFadeIn() {
    //   let banners = document.querySelectorAll(".bar-container");
    //   let trig = document.querySelector("#dashboard");
    //   banners.forEach((chart) => {
    //     let chartFill = chart.querySelectorAll(".w3-container");

    //     let tl = gsap.timeline().from(
    //       chartFill,
    //       {
    //         opacity: 0.75,
    //         // ease: "power4.in",

    //         width: 0,
    //         stagger: 0.25,
    //       },
    //       1.5
    //     );
    //     // .from(headings, { y: 50, stagger: 0.25, autoAlpha: 0 }, 0);

    //     ScrollTrigger.create({
    //       trigger: trig,
    //       start: "top 50%",
    //       // markers: true,
    //       toggleActions: "play none none reverse",
    //       animation: tl,
    //     });
    //   });
    // },

    fadeInListText() {
      var sections = gsap.utils.toArray("section");

      sections.forEach((elem) => {
        var trigger = elem.querySelector("h3");
        var headlines = elem.querySelectorAll("li");

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: trigger,
            start: "+=200 80%",

            end: "+=200 40%",
            scrub: true,
            // markers: true,
            toggleActions: "play none none none",
          },
        });

        tl.from(
          headlines,
          {
            opacity: 0,
            duration: 0.5,
            stagger: 0.1,
            y: -20,
          },
          0.8
        ).to(headlines, { opacity: 1, duration: 0.5, stagger: 0.1, y: 0 }, 0.8);
      });
    },

    pathwaysFadeIn() {
      const schoolTitle = gsap.from(
        ".half li",
        { x: 30, opacity: 0, duration: 0.3, stagger: 0.15, paused: true },
        2
      );

      ScrollTrigger.create({
        trigger: ".half li",
        start: "top 60%",
        onEnter: () => schoolTitle.play(),
      });

      ScrollTrigger.create({
        trigger: ".half li",
        start: "top bottom",
        onLeaveBack: () => schoolTitle.pause(0),
      });
    },
    parallaxBground() {
      gsap.utils.toArray(".image").forEach((section) => {
        gsap.fromTo(
          section,
          {
            // y: () => -section.offsetHeight
          },
          {
            scrollTrigger: {
              trigger: section.parentElement,
              scrub: true,
            },
            y: section.parentElement.offsetHeight - section.offsetHeight,
            ease: "none",
          }
        );
      });
    },
  },
};
</script>
