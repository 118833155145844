<template>
  <div class="bar-container">
    <p class="title">
      {{ title }}
    </p>
    <div class="grid-container">
      <div class="w3-light-grey w3-round-xlarge">
        <div class="w3-container w3-round-xlarge" :style="style"></div>
      </div>
      <p class="figure">{{ figure }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: ["percent", "figure", "title"],
  //   computed: {
  //     computedPercent() {
  //       return "width: " + this.percent;
  //     },
  //   },

  computed: {
    style() {
      return "width: " + this.percent + "%";
    },
  },
};
</script>
