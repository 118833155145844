<template>
  <div class="intro">
    <div id="interior">
      <!-- <h3>Strategic Plan</h3> -->
      <h1><span>TCNJ 2027:</span><br />Extending Our Excellence</h1>
      <p>
        The transformative power of education to develop critical thinkers,
        responsible citizens, and lifelong learners and leaders is central to
        The College of New Jersey’s mission. Through an academic transformation
        in the early 2000s, TCNJ realized this across its undergraduate programs
        and limited number of graduate programs. We now have an opportunity to
        further our public mission by preserving the distinctive educational
        quality and outcomes long associated with TCNJ and extending such
        excellence to new audiences. We express this vision in a new strategy
        for the college, <em>TCNJ 2027: Extending Our Excellence.</em>
      </p>
      <p>
        <a
          download
          href="https://strategicplanning.tcnj.edu/wp-content/uploads/sites/133/2022/09/TCNJ-Strategic-Plan-2027.pdf"
          >Download Full <br id="download-break" />Plan (PDF)
          <img
            src="https://strategicplanning.tcnj.edu/custom/strategic-plan/img/download-arrow.svg"
            alt="download" /></a
        ><br /><br />
        <a
          download
          href="https://strategicplanning.tcnj.edu/wp-content/uploads/sites/133/2023/01/Five-Key-Elements-of-TCNJs-Strategic-Direction.pdf"
          >Download Five Key Elements of TCNJ's Strategic Direction (PDF)
          <img
            src="https://strategicplanning.tcnj.edu/custom/strategic-plan/img/download-arrow.svg"
            alt="download"
        /></a>
      </p>
      <a href="https://tcnj.edu">
        <img
          src="https://strategicplanning.tcnj.edu/custom/strategic-plan/img/tcnj-body-logo.svg"
          alt="TCNJ Logo"
          id="body-logo"
        />
      </a>
      <a class="scroll-to-link">
        <img
          src="https://strategicplanning.tcnj.edu/custom/strategic-plan/img/down-arrow.svg"
          alt="Click to scroll down"
          id="down-arrow"
      /></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "IntroBlock",
};
</script>
